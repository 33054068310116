import React from 'react'
import logo from '../img/logom1motors.png'

const Header = () => {
  return (
    <>
      <header className="header">
        <div className="logoContainer">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </header>
    </>
  )
};

export default Header;