import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";

function Form() {
  const [formData, setFormData] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    celular: '',
    telefone: '',
    nascimento: '',
    rg: '',
    cpf: '',
    investimento: '',
    estadocivil: '',
    cep: '',
    rua: '',
    numeroresidencial: '',
    complemento: '',
    cidade: '',
    estado: '',
    formacao: '',
    empresa: '',
    negocio: '',
    tempoempresa: '',
    funcao: '',
    referencias: '',
    experienciasanteriores: '',
    nomeconjuge: '',
    rgconjuge: '',
    cpfconjuge: '',
    previsaoentradafranquia: '',
    principalfontederenda: '',
    opcao1: '',
    opcao2: '',
    opcao3: '',
    mensagem: '',
    conheceu: '',
    observacao: '',
    antecedenteCriminal: false,
    nomeLimpo: false,
    documento: null,
  });

  const [showComplementoAlert, setShowComplementoAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [fileFeedback, setFileFeedback] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (name === 'cep' && value.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
        if (response.data && !response.data.erro) {
          setFormData((prevData) => ({
            ...prevData,
            rua: response.data.logradouro,
            cidade: response.data.localidade,
            estado: response.data.uf
          }));

          setShowComplementoAlert(true);
        } else {
          alert('CEP inválido ou não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar o CEP:', error);
        alert('Erro ao buscar o CEP.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === 'documento' && formData.documento) {
        formDataToSend.append(key, formData.documento);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch('https://automacao-n8n.px80eh.easypanel.host/webhook/qulificacao', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        const result = await response.json();
        showModalSuccess();

        setFileFeedback('');
        setIsFileSelected(false);

        setFormData({
          nome: '', sobrenome: '', email: '', celular: '', telefone: '', nascimento: '', rg: '', cpf: '',
          investimento: '', estadocivil: '', documento: null, cep: '', rua: '', numeroresidencial: '', complemento: '', cidade: '', estado: '',
          formacao: '', empresa: '', negocio: '', tempoempresa: '', funcao: '', referencias: '', experienciasanteriores: '',
          nomeconjuge: '', rgconjuge: '', cpfconjuge: '', previsaoentradafranquia: '', principalfontederenda: '', mensagem: '',
          opcao1: '', opcao2: '', opcao3: '', conheceu: '', observacao: '', antecedenteCriminal: false, nomeLimpo: false,
        });
      } else {
        const errorResult = await response.json();
        console.error('Erro no envio:', errorResult);
        alert('Houve um erro ao atualizar Cadastro. Tente preencher o formulário novamente!', false);
      }
    } catch (error) {
      console.error('Erro ao enviar:', error);
      alert('Houve um erro ao atualizar Cadastro. Tente preencher o formulário novamente!', false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )) {
      setFormData((prevData) => ({
        ...prevData,
        documento: file
      }));
      setFileFeedback('Arquivo pronto para envio.');
      setIsFileSelected(true);
    } else {
      alert('Apenas arquivos PDF e DOC são permitidos.');
      setIsFileSelected(false);
    }
  };

  const handleSelectChange = (field, selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : ''
    }));
  };

  const confirmation = [
    { value: "Sim", label: "Sim" },
    { value: "Nao", label: "Não" }
  ];

  const findUs = [
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "google", label: "Google" },
    { value: "site", label: "Site da M1 Motors" },
    { value: "cliente", label: "Já fui cliente" },
  ];

  const civil = [
    { value: "solteiro", label: "Solteiro(a)" },
    { value: "casado", label: "Casado(a)" },
    { value: "divorciado", label: "Divorciado(a)" },
    { value: "viuvo", label: "Viúvo(a)" },
    { value: "uniao", label: "União Estável" },
  ];

  const isSingle = formData.estadocivil === 'solteiro';

  const showModalSuccess = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    const rawValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(rawValue / 100);

    setFormData((prevData) => ({
      ...prevData,
      investimento: formattedValue,
    }));
  };

  const handleCPFChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 9) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value.length > 6) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3');
    } else if (value.length > 3) {
      value = value.replace(/(\d{3})(\d{3})/, '$1.$2');
    }

    setFormData((prevData) => ({
      ...prevData,
      cpf: value
    }));
  };

  const handleCelularChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 6) {
      value = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (value.length > 2) {
      value = value.replace(/(\d{2})(\d{0,5})/, '($1) $2');
    }

    setFormData((prevData) => ({
      ...prevData,
      celular: value,
    }));
  };

  const handleTelefoneChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    if (value.length > 6) {
      value = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else if (value.length > 2) {
      value = value.replace(/(\d{2})(\d{0,4})/, '($1) $2');
    }

    setFormData((prevData) => ({
      ...prevData,
      telefone: value,
    }));
  };

  return (
    <div className="App">
      <h2 className="benefitsTitleForm">
        Ficha de <span style={{ color: '#FF3038' }}>Qualificação</span>
      </h2>
      <p className='formSub'>
        Olá, preencha a ficha de qualificação, com os dados restantes, para darmos continuidade ao processo.
      </p>

      <form className="formInputsContainer" onSubmit={handleSubmit}>
        <p>Dados Pessoais</p>

        <div className="input-row">
          <div className="input-column">
            <h2>Nome</h2>
            <input
              type="text"
              name="nome"
              placeholder="Digite seu nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Sobrenome</h2>
            <input
              type="text"
              name="sobrenome"
              placeholder="Digite seu sobrenome"
              value={formData.sobrenome}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Email</h2>
            <input
              type="email"
              name="email"
              placeholder="Digite seu email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Celular</h2>
            <input
              type="tel"
              name="celular"
              placeholder="+55"
              value={formData.celular}
              onChange={handleCelularChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Telefone</h2>
            <input
              type="tel"
              name="telefone"
              placeholder="+55"
              value={formData.telefone}
              onChange={handleTelefoneChange}
            />
          </div>

          <div className="input-column">
            <h2>Data de nascimento</h2>
            <input
              type="date"
              name="nascimento"
              value={formData.nascimento}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Registro civil (RG)</h2>
            <input
              type="text"
              name="rg"
              placeholder="Digite os números do seu RG"
              value={formData.rg}
              onChange={handleChange}
            />
          </div>

          <div className="input-column">
            <h2>CPF</h2>
            <input
              type="text"
              name="cpf"
              placeholder="Digite os números do seu CPF"
              value={formData.cpf}
              onChange={handleCPFChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Capital para investimento</h2>
            <input
              type="text"
              name="investimento"
              placeholder="R$"
              value={formData.investimento}
              onChange={handleCurrencyChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Estado civil</h2>
            <Select
              value={civil.find(option => option.value === formData.estadocivil)}
              onChange={(selectedOption) => handleSelectChange("estadocivil", selectedOption)}
              options={civil}
              placeholder="Estado civil"
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: "#151717",
                  color: "#fff",
                  border: state.isFocused ? "1px solid #FF3038" : "1px solid #fff",
                  borderRadius: '7px',
                  width: '100%',
                  height: '37px',
                  boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                  outline: "none",
                  ":hover": {
                    borderColor: "#FF3038",
                  },
                  "@media (max-width: 768px)": {
                    width: '90%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                  "@media (max-width: 375px)": {
                    width: '87%',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#ccc",
                  textAlign: 'left',
                  fontSize: "14px",
                  marginBottom: '10px',
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                  "@media (max-width: 480px)": {
                    fontSize: "14px",
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                  color: "#fff",
                  ":active": {
                    backgroundColor: "#FF3038",
                  },
                  borderBottom: "1px solid #333",
                  padding: '10px',
                  ":last-child": {
                    borderBottom: "none",
                  },
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#151717",
                  marginTop: 0,
                  marginBottom: 0,
                  width: '100%',
                  zIndex: 999,
                  "@media (max-width: 768px)": {
                    width: '100%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                  backgroundColor: "#151717",
                  border: "none",

                }),
                singleValue: (base) => ({
                  ...base,
                  color: "#fff",
                  marginBottom: '10px',
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#fff",
                  ":hover": {
                    color: "#FF3038"
                  }
                })
              }}
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Envie seu comprovante (PDF ou DOC)</h2>
            <label className="custom-file-upload">
              <input
                type="file"
                name="documento"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
              Informe sua declaração de imposto de renda
            </label>
            <p style={{ fontSize: '16px', color: isFileSelected ? 'green' : 'red' }}>
              {fileFeedback || 'Nenhum arquivo selecionado'}
            </p>
          </div>
          <div className="input-column" />
        </div>

        <p>Endereço</p>

        <div className="input-row">
          <div className="input-column">
            <h2>CEP <span style={{ color: '#FF3038', fontWeight: 'bold' }}>*</span></h2>
            <input
              type="text"
              name="cep"
              placeholder="Digite seu CEP"
              value={formData.cep}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Rua</h2>
            <input
              type="text"
              name="rua"
              placeholder="Digite sua Rua"
              value={formData.rua}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Número</h2>
            <input
              type="text"
              name="numeroresidencial"
              placeholder="Digite o número da sua residencia"
              value={formData.numeroresidencial}
              onChange={handleChange}
            />
            {showComplementoAlert && (
              <small style={{ color: '#FF3038', marginTop: '5px' }}>
                Por favor, não esqueça de preencher o numero.
              </small>
            )}
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Complemento</h2>
            <input
              type="text"
              name="complemento"
              placeholder="Digite o complemento"
              value={formData.complemento}
              onChange={handleChange}
            />
          </div>

          <div className="input-column">
            <h2>Cidade</h2>
            <input
              type="text"
              name="cidade"
              placeholder="Cidade"
              value={formData.cidade}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Estado</h2>
            <input
              type="text"
              name="estado"
              placeholder="Estado"
              value={formData.estado}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <p>Dados Profissionais</p>

        <div className="input-row">
          <div className="input-column">
            <h2>Formação / Curso</h2>
            <input
              type="text"
              name="formacao"
              placeholder="Formação ou curso"
              value={formData.formacao}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Empresa</h2>
            <input
              type="text"
              name="empresa"
              placeholder="Empresa"
              value={formData.empresa}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Tipo de negócio</h2>
            <input
              type="text"
              name="negocio"
              placeholder="Tipo de negócio"
              value={formData.negocio}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Tempo de vínculo</h2>
            <input
              type="text"
              name="tempoempresa"
              placeholder="Tempo de vínculo"
              value={formData.tempoempresa}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Função / Cargo</h2>
            <input
              type="text"
              name="funcao"
              placeholder="Função ou cargo"
              value={formData.funcao}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Informe referências comerciais (nome e contato)</h2>
            <input
              type="text"
              name="referencias"
              placeholder="Referências comerciais"
              value={formData.referencias}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Informe experiências anteriores</h2>
            <input
              type="text"
              name="experienciasanteriores"
              placeholder="Experiências anteriores"
              value={formData.experienciasanteriores}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-column" />
        </div>

        <p>Dados Cônjuge</p>

        <div className="input-row">
          <div className="input-column">
            <h2>Nome completo</h2>
            <input
              type="text"
              name="nomeconjuge"
              placeholder="Nome completo"
              value={formData.nomeconjuge}
              onChange={handleChange}
              disabled={isSingle}
              required={!isSingle}
            />
          </div>

          <div className="input-column">
            <h2>Registro civil (RG)</h2>
            <input
              type="text"
              name="rgconjuge"
              placeholder="Registro civil (RG)"
              value={formData.rgconjuge}
              onChange={handleChange}
              disabled={isSingle}
              required={!isSingle}
            />
          </div>
        </div>


        <div className="input-row">
          <div className="input-column">
            <h2>CPF</h2>
            <input
              type="text"
              name="cpfconjuge"
              placeholder="CPF do cônjuge"
              value={formData.cpfconjuge}
              onChange={handleChange}
              disabled={isSingle}
              required={!isSingle}
            />
          </div>
          <div className="input-column" />
        </div>

        <p>Dados Complementares</p>

        <div className="input-row">
          <div className="input-column">
            <h2>Previsão de entrada na franquia</h2>
            <input
              type="text"
              name="previsaoentradafranquia"
              placeholder="Previsão de entrada na franquia"
              value={formData.previsaoentradafranquia}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>A franquia será sua principal fonte de renda?</h2>
            <Select
              value={confirmation.find(option => option.value === formData.principalfontederenda)}
              onChange={(selectedOption) => handleSelectChange("principalfontederenda", selectedOption)}
              options={confirmation}
              placeholder="Sim ou Não"
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: "#151717",
                  color: "#fff",
                  border: state.isFocused ? "1px solid #FF3038" : "1px solid #fff",
                  borderRadius: '7px',
                  width: '36%',
                  height: '37px',
                  boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                  outline: "none",
                  ":hover": {
                    borderColor: "#FF3038",
                  },
                  "@media (max-width: 768px)": {
                    width: '90%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                  "@media (max-width: 375px)": {
                    width: '87%',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#ccc",
                  textAlign: 'left',
                  fontSize: "14px",
                  marginBottom: '10px',
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                  "@media (max-width: 480px)": {
                    fontSize: "14px",
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                  color: "#fff",
                  ":active": {
                    backgroundColor: "#FF3038",
                  },
                  borderBottom: "1px solid #333",
                  padding: '10px',
                  ":last-child": {
                    borderBottom: "none",
                  },
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#151717",
                  marginTop: 0,
                  marginBottom: 0,
                  width: '36%',
                  zIndex: 999,
                  "@media (max-width: 768px)": {
                    width: '100%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                  backgroundColor: "#151717",
                  border: "none",

                }),
                singleValue: (base) => ({
                  ...base,
                  color: "#fff",
                  marginBottom: '10px',
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#fff",
                  ":hover": {
                    color: "#FF3038"
                  }
                })
              }}
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Por que você gostaria de ter seu próprio negócio?</h2>
            <input
              type="text"
              name="mensagem"
              placeholder="Se desejar, deixe uma mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              className="input-mensagem"
              required
            />
          </div>
        </div>

        <p>Praça de Interesse</p>

        <div className="input-row">
          <div className="input-column">
            <h2>Opção 1 - Cidade / Bairro Franquia</h2>
            <input
              type="text"
              name="opcao1"
              placeholder="Opção 1"
              value={formData.opcao1}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-column">
            <h2>Opção 2 - Cidade / Bairro Franquia</h2>
            <input
              type="text"
              name="opcao2"
              placeholder="Opção 2"
              value={formData.opcao2}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-column">
            <h2>Opção 3 - Cidade / Bairro Franquia</h2>
            <input
              type="text"
              name="opcao3"
              placeholder="Opção 3"
              value={formData.opcao3}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-column" />
        </div>

        <div className="input-row" style={{ marginTop: '4%' }}>
          <div className="input-column">
            <h2>Como nos conheceu?</h2>
            <Select
              value={findUs.find(option => option.value === formData.conheceu)}
              onChange={(selectedOption) => handleSelectChange("conheceu", selectedOption)}
              options={findUs}
              placeholder="Como nos conheceu? "
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: "#151717",
                  color: "#fff",
                  border: state.isFocused ? "1px solid #FF3038" : "1px solid #fff",
                  borderRadius: '7px',
                  width: '100%',
                  height: '37px',
                  boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                  outline: "none",
                  ":hover": {
                    borderColor: "#FF3038",
                  },
                  "@media (max-width: 768px)": {
                    width: '90%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                  "@media (max-width: 375px)": {
                    width: '87%',
                  },
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#ccc",
                  textAlign: 'left',
                  fontSize: "14px",
                  marginBottom: '10px',
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                  "@media (max-width: 480px)": {
                    fontSize: "14px",
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                  color: "#fff",
                  ":active": {
                    backgroundColor: "#FF3038",
                  },
                  borderBottom: "1px solid #333",
                  padding: '10px',
                  ":last-child": {
                    borderBottom: "none",
                  },
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#151717",
                  marginTop: 0,
                  marginBottom: 0,
                  width: '100%',
                  zIndex: 999,
                  "@media (max-width: 768px)": {
                    width: '100%',
                  },
                  "@media (max-width: 480px)": {
                    width: '100%',
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                  backgroundColor: "#151717",
                  border: "none",

                }),
                singleValue: (base) => ({
                  ...base,
                  color: "#fff",
                  marginBottom: '10px',
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#fff",
                  ":hover": {
                    color: "#FF3038"
                  }
                })
              }}
            />
          </div>

          <div className="input-column">
            <h2>Se desejar deixe uma mensagem</h2>
            <input
              type="text"
              name="observacao"
              placeholder="Se desejar, deixe uma mensagem"
              value={formData.observacao}
              onChange={handleChange}
              className="input-mensagem-final"
            />
          </div>
        </div>

        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              name="antecedenteCriminal"
              checked={formData.antecedenteCriminal}
              onChange={handleChange}
              required
            />
            Estou de acordo que a M1 Motors pode pesquisar meus Antecedentes Criminais.
          </label>

          <label>
            <input
              type="checkbox"
              name="nomeLimpo"
              checked={formData.nomeLimpo}
              onChange={handleChange}
              required
            />
            Estou de acordo que a M1 Motors pode pesquisar se estou com nome limpo (Serasa, SPC, Boa Vista).
          </label>
        </div>


        <div style={{ alignItems: 'center' }}>
          <button type="submit" className='btnForm'>Atualizar cadastro</button>
        </div>
      </form>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Formulário Enviado com Sucesso!</h3>
            <p>Obrigado por preencher a ficha de qualificação.</p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}

    </div>
  );
}

export default Form;